import React from 'react';
import { connect } from 'react-redux';
import Redirect from 'react-router-dom/Redirect';

class HomeComponent extends React.Component {
    componentDidUpdate (prevPros, prevState) {
        if(this.props.authentication.isAuthorized !== prevPros.authentication.isAuthorized) {
            if(this.props.authentication.isAuthorized) {
                return <Redirect to={{ pathname: "/tableau"}}/>
            }
            return <Redirect to='/login'/>
        }
    }

    render() {
        const authorized = this.props.authentication.isAuthorized;
        if(authorized) {
            return <Redirect to={{ pathname: "/tableau"}}/>
        }
        return <Redirect to='/login'/>
    }
}
export default connect(state => state.appContext, null)(HomeComponent);
