import { LOCATION_CHANGE } from 'react-router-redux';
import { toastMessage } from '../components/common/message/message'
import { apiGet, apiPost, apiPut, parseQuery, hasPermission, redirectTo } from './apiWrapper';
import { updateObject, updateObjectProperty } from './storeFunctions';
import { writeErr, writeDebug, writeWarn } from '../components/common/logger';
import { setActiveLanguage } from 'react-localize-redux';
import { push } from 'react-router-redux';
import { isNullOrUndefined } from 'util';

const userLogin = "USER_LOGIN";
const userVerif = "USER_VERIFICATION";
const userLoginReset = "USER_LOGIN_RESET";
const userLogout = "USER_LOGOUT";
const userLoginRequest = "USER_LOGIN_REQUEST";
const userVerifyRequest = "USER_VERIFY_REQUEST";
const userResendRequest = "USER_RESEND_REQUEST";
const userLoginFailed = "USER_LOGIN_FAILED";
const userExpired = "LOGIN_EXPIRED";
const changingLanguage = "CHANGING_LANGUAGE";
const languageChanged = "LANGUAGE_CHANGED";
const updateUserRequest = "UPDATE_USER_REQUEST";
const updateUserSuccess = "UPDATE_USER_SUCCESS";
const updateUserFailed = "UPDATE_USER_FAILED";
const settingsReset = "SETTINGS_RESET";
const userChangePwd = "USER_CHANGE_PWD";

export const errorType = 'MAKE_USER_UNHAPPY';

const initAuth = { isAuthorized: false, error: undefined, loading: false };
const initSettings = { isUpdating: false, update: null, error: null };
const initDomains = { isLoading: false, report: null, country: null, error: null };
const initialState = restoreFromStorage({
    isLoading: false, isLoaded: false, domains: { ...initDomains }, authentication: { ...initAuth }, settings: { ...initSettings },
});

//action creator for redux
export const appContextActions = {
    fetchTranslations: (language, addTranslationForLanguage, setAsActive) => async (dispatch, getState) => {
        const locData = localStorage.getItem('locales_data');
        const locLang = localStorage.getItem('locales_lang');
        const locVersion = localStorage.getItem('locales_version');

        dispatch({ type: changingLanguage });
        if (locLang === language) {
            if (locData !== null && locData !== undefined) {
                dispatch(addTranslationForLanguage(JSON.parse(locData), language));
                apiGet(`/lang/${language}`, { version: true }, res => {
                    if (res.version !== locVersion) {
                        writeDebug(`Local translations with version:[${locVersion}] is out-of-date loading fresh data`);
                        apiGet(`/lang/${language}`, { version: true, data: true }, res => {
                            localStorage.setItem('locales_data', JSON.stringify(res.data));
                            localStorage.setItem('locales_version', res.version);
                            dispatch(addTranslationForLanguage(res.data, language));
                            if (setAsActive === true) {
                                dispatch(setActiveLanguage(language));
                            }
                            dispatch({ type: languageChanged });
                        });
                    } else {
                        writeDebug(`Using local translations with version:[${locVersion}]`)
                        dispatch({ type: languageChanged });
                    }
                });
            } else {
                writeDebug(`Local translations not found loading`);
                apiGet(`/lang/${language}`, { version: true, data: true }, res => {
                    localStorage.setItem('locales_data', JSON.stringify(res.data));
                    localStorage.setItem('locales_version', res.version);
                    dispatch(addTranslationForLanguage(res.data, language));
                    if (setAsActive === true) {
                        dispatch(setActiveLanguage(language));
                    }
                    dispatch({ type: languageChanged });
                });
            }
        } else {
            writeDebug(`Local translations language does not match loading fresh data`);
            apiGet(`/lang/${language}`, { version: true, data: true }, res => {
                localStorage.setItem('locales_data', JSON.stringify(res.data));
                localStorage.setItem('locales_lang', language);
                localStorage.setItem('locales_version', res.version);
                dispatch(addTranslationForLanguage(res.data, language));
                if (setAsActive === true) {
                    dispatch(setActiveLanguage(language));
                }
                dispatch({ type: languageChanged });
            });
        }
    },
    login: (username, pswd, url) => async (dispatch, getState) => {
        if (getState().appContext.authentication.loading) {
            return;
        }
        dispatch({ type: userLoginRequest });
        apiPost(`api/account/login`, undefined, { username: username, password: pswd, grant_type: 'password' }, res => {
            if (!res.data.success) {
                dispatch({ type: userLoginFailed, data: res.data });
            } else {
                dispatch({ type: userLogin, data: res.data });
                redirectToBack(dispatch, url);
            }
        }, err => {
            dispatch({ type: userLoginFailed, data: { isAuthorized: false, error: err, expire: null, loading: false } });
        });
    },
    verify: (username, pswd, guid, smstoken, url) => async (dispatch, getState) => {
        if (getState().appContext.authentication.loading) {
            return;
        }
        dispatch({ type: userVerifyRequest });
        apiPost(`api/account/verify`, undefined, { username: username, password: pswd, sms_token: smstoken, guid: guid }, res => {
            if (!res.data.success) {
                dispatch({ type: userLoginFailed, data: res.data });
            } else {
                dispatch({ type: userVerif, data: res.data });
                redirectToBack(dispatch, url);
            }
        }, err => {
            dispatch({ type: userLoginFailed, data: { isAuthorized: false, error: err, expire: null, loading: false } });
        });
    },
    verifyChangePwd: (username, pswd, isAuthorized, url) => async (dispatch, getState) => {
        if (getState().appContext.authentication.loading) {
            return;
        }
        dispatch({ type: userVerifyRequest });
        apiPost(`api/account/verifyChangePwd`, undefined, { username: username, password: pswd, isAuthorized: isAuthorized }, res => {
            if (!res.data.success) {
                dispatch({ type: userLoginFailed, data: res.data });
            } else {
                dispatch({ type: userVerif, data: res.data });
                redirectToBack(dispatch, url);
            }
        }, err => {
            dispatch({ type: userLoginFailed, data: { isAuthorized: false, error: err, expire: null, loading: false } });
        });
    },
    resend: (username, pswd, guid, url) => async (dispatch, getState) => {
        if (getState().appContext.authentication.loading) {
            return;
        }
        dispatch({ type: userResendRequest });
        apiPost(`api/account/resend`, undefined, { username: username, password: pswd, guid: guid }, res => {
            if (!res.data.success) {
                dispatch({ type: userLoginFailed, data: res.data });
            } else {
                dispatch({ type: userLogin, data: res.data });
                redirectToBack(dispatch, url);
            }
        }, err => {
            dispatch({ type: userLoginFailed, data: { isAuthorized: false, error: err, expire: null, loading: false } });
        });
    },
    logout: (url) => async (dispatch, getState) => {
        localStorage.removeItem('auth');
        dispatch({ type: userLogout })
        redirectToBack(dispatch, url);
    },
    redirectBack: (url) => async (dispatch, getState) => redirectToBack(dispatch, url),
    resetLoginAttempt: () => async (dispatch, getState) => dispatch({ type: userLoginReset }),

    updateUser: (userId, email, name, surname, language) => async (dispatch, getState) => {
        dispatch({ type: updateUserRequest });
        apiPut(`api/user/user/${userId}`, undefined,
            { name, surname, email, language }, (res) => {

                if (isNullOrUndefined(res.error)) {
                    dispatch({ type: updateUserSuccess, data: res, email, name, surname, language });
                    toastMessage("Error_Message_SaveUserSuccessfull", "200");
                }
                else {
                    dispatch({ type: updateUserSuccess, data: res, email, name, surname, language });
                    toastMessage("Error_Message_SaveUserFailed", "400");
                }
            }, err => {
                writeErr(err);
                dispatch({ type: updateUserFailed, error: err });
                toastMessage(err.data.error.message, err.data.error.code);
            }
        );
    },
    changePwd: () => async (dispatch, getState) => {
        dispatch({ type: userChangePwd })
        redirectTo('/login');
    },

    resetSettings: () => async (dispatch, getState) => {
        dispatch({ type: settingsReset });
    }

};

// store reducer
export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case changingLanguage:
            return updateObject(state, { langLoading: true });
        case languageChanged:
            return updateObject(state, { langLoading: false });
        case LOCATION_CHANGE:
            return { ...state, location: action.payload.pathname };
        case userLoginRequest:
            return updateObject(state, { authentication: updateObject(state.authentication, { loading: true, error: undefined }) });
        case userVerifyRequest:
            return updateObject(state, { authentication: updateObject(state.authentication, { loading: true, error: undefined }) });
        case userResendRequest:
            return updateObject(state, { authentication: updateObject(state.authentication, { loading: true, error: undefined }) });
        case userLogin:
            let dataul = { isAuthorized: action.data.success, ...action.data, loading: false};
            if( action.data.success){
                localStorage.setItem('auth', JSON.stringify(dataul));
            }
            return updateObject({ ...state, isLoaded: false, isLoading: false }, { authentication: updateObject(state.authentication, dataul) });

        case userVerif:
            let datauv = { isAuthorized: action.data.success, ...action.data, loading: false};
            localStorage.setItem('auth', JSON.stringify(datauv));
            return updateObject({ ...state, isLoaded: false, isLoading: false }, { authentication: updateObject(state.authentication, datauv) });
        
        case userLoginFailed:
            let dataulf = { isAuthorized: action.data.success, ...action.data, loading: false };
            return updateObject({ ...state, isLoading: false }, { authentication: updateObject(state.authentication, dataulf) })
        case userLogout:
            return {
                ...state, isChangePwd: false, navItems: [], authentication: { ...initAuth }, domains: { ...initDomains }, settings: { ...initSettings }
            };
        case userLoginReset:
            return updateObject(state, { authentication: updateObject(state.authentication, { error: undefined }) });

        case updateUserRequest:
            return updateObject(state, { settings: updateObject(state.settings, { isUpdating: true, update: null, error: null }) });
        case updateUserSuccess:
            return updateObject(state, {
                settings: updateObject(state.settings, { isUpdating: false, update: action.data, error: null }),
                authentication: action.data == 0 ? state.authentication : updateObject(state.authentication, {
                    profile: updateObject(state.authentication.profile, {
                        name: action.name, email: action.email, surname: action.surname, agentLanguage: action.language
                    })
                })
            });
        case updateUserFailed:
            return updateObject(state, { settings: updateObject(state.settings, { isUpdating: false, update: null, error: action.error }) });
        case settingsReset:
            return updateObject(state, { settings: { ...initSettings } });
        case userExpired:
            localStorage.removeItem('auth');
            return updateObject(state, { authentication: { ...initAuth } });
        case userChangePwd:
            return updateObject(state, {isChangePwd: true });
        default:
            return state;
    }
}

function redirectToBack(dispatch, url) {
    const q = parseQuery(url);
    const redir = q.returnUrl !== null && q.returnUrl !== undefined ? decodeURIComponent(q.returnUrl) : '/';
    writeDebug("Redirecting to " + redir);
    dispatch(push(redir));
}

function restoreFromStorage(initial) {
    let auth = localStorage.getItem('auth');

    if (auth !== null && auth !== undefined) {
        auth = JSON.parse(auth);
        if (auth === null || auth === undefined) {
            return initial;
        }

        if (Date.parse(auth.expire) < Date.now()) {
            localStorage.removeItem('auth');
            return initial;
        }
    }
    return updateObject(initial, { authentication: updateObject(initial.authentication, { ...auth, loading: false }) });
}