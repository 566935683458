// IE support for array.find function
(function () {
    if (Array.prototype.find === undefined) {
        // eslint-disable-next-line
        Object.defineProperty(Array.prototype, 'find', {
            value: function (predicate) {

                if (this === null) {
                    throw new TypeError('this is null or not defined');
                }

                var obj = Object(this);
                var len = obj.length >>> 0;

                if (typeof predicate !== 'function') {
                    throw new TypeError('predicate must be a function');
                }

                var thisArg = arguments[1];
                var index = 0;

                while (index < len) {
                    var iValue = obj[index];
                    if (predicate.call(thisArg, iValue, index, obj)) {
                        return iValue;
                    }
                    index++;
                }
                return undefined;
            }
        });
    }
    if (Array.prototype.includes === undefined) {
        // eslint-disable-next-line
        Object.defineProperty(Array.prototype, 'includes', {
            configurable: true,
            value: function includes(searchElement) {
                var O = Object(this);
                var len = parseInt(O.length, 10) || 0;
                if (len === 0) {
                    return false;
                }
                var n = parseInt(arguments[1], 10) || 0;
                var k;
                if (n >= 0) {
                    k = n;
                } else {
                    k = len + n;
                    if (k < 0) {
                        k = 0;
                    }
                }
                var currentElement;
                while (k < len) {
                    currentElement = O[k];
                    if (searchElement === currentElement) {
                        return true;
                    }
                    k++;
                }
                return false;
            },
            writable: true
        });
    }

    if (String.prototype.startsWith === undefined) {
        // eslint-disable-next-line
        Object.defineProperty(String.prototype, 'startsWith', {
            configurable: true,
            value: function startsWith(needle) {
                if (this.indexOf(needle) === 0) {
                    return true;
                }
                return false;
            }
        });
    }

    if (String.prototype.includes === undefined) {
        // eslint-disable-next-line
        Object.defineProperty(String.prototype, 'includes', {
            value: function (needle) {
                if (this.indexOf(needle) >= 0 ) {
                    return true;
                }
                return false;
            }
        });
    }

    if (!Array.from) {
        Array.from = (function () {
        var toStr = Object.prototype.toString;
        var isCallable = function (fn) {
            return typeof fn === 'function' || toStr.call(fn) === '[object Function]';
        };
        var toInteger = function (value) {
            var number = Number(value);
            if (isNaN(number)) { return 0; }
            if (number === 0 || !isFinite(number)) { return number; }
            return (number > 0 ? 1 : -1) * Math.floor(Math.abs(number));
        };
        var maxSafeInteger = Math.pow(2, 53) - 1;
        var toLength = function (value) {
            var len = toInteger(value);
            return Math.min(Math.max(len, 0), maxSafeInteger);
        };
    
        // The length property of the from method is 1.
        return function from(arrayLike/*, mapFn, thisArg */) {
            // 1. Let C be the this value.
            var C = this;
    
            // 2. Let items be ToObject(arrayLike).
            var items = Object(arrayLike);
    
            // 3. ReturnIfAbrupt(items).
            if (arrayLike == null) {
            throw new TypeError('Array.from requires an array-like object - not null or undefined');
            }
    
            // 4. If mapfn is undefined, then let mapping be false.
            var mapFn = arguments.length > 1 ? arguments[1] : void undefined;
            var T;
            if (typeof mapFn !== 'undefined') {
            // 5. else
            // 5. a If IsCallable(mapfn) is false, throw a TypeError exception.
            if (!isCallable(mapFn)) {
                throw new TypeError('Array.from: when provided, the second argument must be a function');
            }
    
            // 5. b. If thisArg was supplied, let T be thisArg; else let T be undefined.
            if (arguments.length > 2) {
                T = arguments[2];
            }
            }
    
            // 10. Let lenValue be Get(items, "length").
            // 11. Let len be ToLength(lenValue).
            var len = toLength(items.length);
    
            // 13. If IsConstructor(C) is true, then
            // 13. a. Let A be the result of calling the [[Construct]] internal method 
            // of C with an argument list containing the single item len.
            // 14. a. Else, Let A be ArrayCreate(len).
            var A = isCallable(C) ? Object(new C(len)) : new Array(len);
    
            // 16. Let k be 0.
            var k = 0;
            // 17. Repeat, while k < len… (also steps a - h)
            var kValue;
            while (k < len) {
            kValue = items[k];
            if (mapFn) {
                A[k] = typeof T === 'undefined' ? mapFn(kValue, k) : mapFn.call(T, kValue, k);
            } else {
                A[k] = kValue;
            }
            k += 1;
            }
            // 18. Let putStatus be Put(A, "length", len, true).
            A.length = len;
            // 20. Return A.
            return A;
        };
        }());
    }

    if (typeof Object.assign !== 'function') {
        // Must be writable: true, enumerable: false, configurable: true
        Object.defineProperty(Object, "assign", {
          value: function assign(target, varArgs) { // .length of function is 2
            'use strict';
            if (target === null || target === undefined) {
              throw new TypeError('Cannot convert undefined or null to object');
            }
      
            var to = Object(target);
      
            for (var index = 1; index < arguments.length; index++) {
              var nextSource = arguments[index];
      
              if (nextSource !== null && nextSource !== undefined) { 
                for (var nextKey in nextSource) {
                  // Avoid bugs when hasOwnProperty is shadowed
                  if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                    to[nextKey] = nextSource[nextKey];
                  }
                }
              }
            }
            return to;
          },
          writable: true,
          configurable: true
        });
    }

    // https://tc39.github.io/ecma262/#sec-array.prototype.findindex
    if (!Array.prototype.findIndex) {
        Object.defineProperty(Array.prototype, 'findIndex', {
        value: function(predicate) {
        // 1. Let O be ? ToObject(this value).
            if (this == null) {
            throw new TypeError('"this" is null or not defined');
            }
    
            var o = Object(this);
    
            // 2. Let len be ? ToLength(? Get(O, "length")).
            var len = o.length >>> 0;
    
            // 3. If IsCallable(predicate) is false, throw a TypeError exception.
            if (typeof predicate !== 'function') {
            throw new TypeError('predicate must be a function');
            }
    
            // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
            var thisArg = arguments[1];
    
            // 5. Let k be 0.
            var k = 0;
    
            // 6. Repeat, while k < len
            while (k < len) {
            // a. Let Pk be ! ToString(k).
            // b. Let kValue be ? Get(O, Pk).
            // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
            // d. If testResult is true, return k.
            var kValue = o[k];
            if (predicate.call(thisArg, kValue, k, o)) {
                return k;
            }
            // e. Increase k by 1.
            k++;
            }
    
            // 7. Return -1.
            return -1;
        },
        configurable: true,
        writable: true
        });
    }

    require('es6-promise').polyfill();
})();
