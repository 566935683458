import * as isaac from 'isaac';

function updateObject(old, toUpdate) {
    return Object.assign({}, old, toUpdate);
}

function updateObjectProperty(old, propName, propValue) {
    const n = {...old};
    n[propName] = propValue;
    return n;
}

function getRandomValue() {
    var res;

    // First we're going to try to use a built-in CSPRNG
    if (window.crypto && window.crypto.getRandomValues) {
        res = new Uint32Array(1);
        window.crypto.getRandomValues(res);
    }
    // Because of course IE calls it msCrypto instead of being standard
    else if (window.msCrypto && window.msCrypto.getRandomValues) {
        res = new Uint32Array(1);
        window.msCrypto.getRandomValues(res);
    }
    // Last resort - we'll use isaac.js to get a random number. It's seeded from Math.random(),
    // so this isn't ideal, but it'll still greatly increase the space of guesses a hacker would
    // have to make to crack the password.
    else {
        res = [(((1 + isaac.random()) * 0x10000) | 0)];
    }

    return res[0];
}

export { updateObject, updateObjectProperty, getRandomValue }