import React from "react";

const SvgUser = props => (
  <svg viewBox="0 0 200 200" {...props}>
    <path
      d="M100.49 33.09c-22.86 0-41.42 18.24-41.42 40.78s18.56 40.79 41.42 40.79 41.44-18.24 41.44-40.79-18.58-40.78-41.44-40.78zm0 8.45c18.22 0 33 14.49 33 32.33s-14.77 32.33-33 32.33-33-14.49-33-32.33 14.78-32.33 33-32.33zm33.65 63.76a4.23 4.23 0 00-2.83 7.8 58 58 0 0128.12 49.58 4.23 4.23 0 108.46 0 66.49 66.49 0 00-32.17-56.8 4.33 4.33 0 00-1.58-.58zm-68.54.16a4.2 4.2 0 00-1.6.54 66.5 66.5 0 00-31.9 56.64 4.23 4.23 0 108.46 0 58.06 58.06 0 0127.88-49.45 4.22 4.22 0 00-2.85-7.77z"
      data-name="Horn\xED menu + Footer"
    />
  </svg>
);

export default SvgUser;

