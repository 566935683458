import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import "./loading.scss";

class Loading extends React.Component {
    render(){
        return (
        <div className="pageloader loading-aligner is-active">
            <div className="loading-center">
                <div className="sr-loading">
                    <div><FontAwesomeIcon icon="spinner" size="8x" pulse /></div>
                </div>
            </div>
        </div>
    );
    }
}

export default Loading;