import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import { localizeReducer } from "react-localize-redux";

import * as AppContext from './appContextStore';
import * as TableauContext from './tableauStore';

export default function configureStore (history, initialState) {
  const reducers = {
      appContext: AppContext.reducer,
      tableau: TableauContext.reducer,
    localize: localizeReducer
  };

  const middleware = [ thunk, routerMiddleware(history) ];

  // In development, use the browser's Redux dev tools extension if installed
  const enhancers = [];
  const isDevelopment = process.env.NODE_ENV === 'development';
  if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
    enhancers.push(window.devToolsExtension());
  }

  return createStore(
      combineReducers({ ...reducers, routing: routerReducer }),
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers)
  );
}
