import React, {Component} from 'react';
import connect from 'react-redux/lib/connect/connect';
import { bindActionCreators } from 'redux';
import { tableauSettingsActions } from '../../store/tableauStore'
import Loading from '../common/loading/loading';
import TableauReport from 'tableau-react-embed';


class TableauComponent extends Component{
    constructor(props){
        super(props);

        this.state = {
            loading: true,
            data:{},
            options: { height:'calc(100vh - 3.25rem)', width: '100%'},
        }   
    }

    componentDidMount(){
       this.props.ensureTableauSettings();
    }   

    render(){
       return(
           <React.Fragment>
               {this.renderLoading()}
           </React.Fragment>
       )
    }

    showError = (error) => {
        if (error === null || error === undefined) {
            return "";
        }
        return (
            <div className="columns">
                <div className="column is-4 is-offset-4">
                    <div className="notification is-danger">{error}</div>
                </div>
            </div>
        );
    };

    renderLoading = () => {
        if (!(!this.props.tableau.tableauSettings.isRequesting && this.props.tableau.tableauSettings.result))
            return (<Loading />);
        if (this.props.tableau.tableauSettings.result.token == -1)
            return (
                <React.Fragment>
                    {this.showError('You do not have permission to access to tableau lab')}
                </React.Fragment>
            )

        return (
            <React.Fragment>
                <TableauReport url={this.props.tableau.tableauSettings.result.url}
                               token={this.props.tableau.tableauSettings.result.token}
                               options={this.state.options}
                               query="?:embed=yes&:comments=no&:toolbar=yes&:refresh=yes&:showShareOptions=false">
                </TableauReport>
            </React.Fragment>
        )
    }

}

export default connect(
    state => ({tableau: state.tableau, app: state.appContext}),
    dispatch => bindActionCreators(tableauSettingsActions, dispatch)
)(TableauComponent);