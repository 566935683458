import React from "react";
import "./navbarButton.scss";
import { registerHook, unregisterHook, isClickTo } from "../../store/clickWrapper";
import classnames from "classnames";
import { withRouter } from 'react-router';

class NavbarButton extends React.Component {
    items = undefined;
    opener = undefined;
    mounted = false;
    state = {
        expanded: false
    };

    componentDidMount() {
        this.mounted = true;
        this.hookId = registerHook(this, (click) => {
            if (this.state.expanded && !isClickTo(click, [this.opener, this.items]) && this.mounted) {
                this.setState({ expanded: false });
            }
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.setState({ expanded: false });
        }
    }

    componentWillUnmount() {
        this.mounted = false;
        unregisterHook(this.hookId);
    }

    render() {
        return (
            <div className={classnames({'navbar-item has-dropdown':true, 'navbar-item-user':this.props.minWidth, 'is-active':this.state.expanded})}>
                <a className='navbar-link' ref={(el) => this.opener = el} onClick={this.handleClick}>
                    {this.props.link}
                </a>
                <div 
                    className={classnames(
                        'navbar-dropdown', 
                        this.props.dropdownClassname,
                        { 'is-right': this.props.isRight }, 
                        { 'is-right-on-smaller-desktop': this.props.isRightOnSmallerDesktop }
                    )} 
                    ref={(el) => this.items = el}
                >
                    {this.props.dropdown}
                </div>
            </div>
        );
    }

    handleClick = () => {
        if(window.innerWidth <= 992) {
            typeof this.props.toggleMobileMenu === 'function' ? this.props.toggleMobileMenu() : this.setState({ expanded: !this.state.expanded });
        } else {
            this.setState({ expanded: !this.state.expanded });
        }
    }
}

export default withRouter(NavbarButton);