import React from 'react';
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { appContextActions } from '../../store/appContextStore'
import { isObjectPathDefined, hasPermission, isOnPath } from '../../store/apiWrapper'
import { writeDebug } from './logger'
import { isNullOrUndefined } from 'util';

const RenderComponent = function (user, component, layout, componentProps, rest) {
    if (!isObjectPathDefined(rest, 'permission') || hasPermission(user, rest.permission)) {
        const L = layout;
        const C = component;
        if (layout)
            return <L component={component} componentProps={componentProps} user={user} pageClass={rest.pageClass || ''} />
        return (
            <C {...componentProps} {...rest}></C>
        );
    }
    else
        return <Redirect to={`/notAuthorized?returnUrl=${encodeURIComponent(window.location.pathname + window.location.search)}`} />
}

const PrivateRoute = ({ component, user, componentProps, layout, ...rest }) => (
    <Route exact={rest.exact} path={rest.path} render={() => {
        const noAuth = user === undefined || user.authentication === undefined || !user.authentication.isAuthorized;
        if (noAuth) {
            writeDebug(`User not authenticated for private path:[${rest.path}] redirecting to login, current location:[${window.location.pathname}]`);
            if (window.location.search.includes('returnUrl')) {
                // if returnUrl is already there reuse it
                return <Redirect to={`/login${window.location.search}`} />
            } else if (isOnPath({ prefix: '/login' })) {
                // if we are already on login dont set returnUrl back to login
                return <Redirect to={`/login`} />
            }
            return <Redirect to={`/login?returnUrl=${encodeURIComponent(window.location.pathname + window.location.search)}`} />
        }
        if (user.authentication.limitedUser && user.authentication.limitedPath !== '' && !isNullOrUndefined(user.authentication.limitedPath) && user.authentication.limitedPath != window.location.href) {
            return <Redirect to={`/logout`} />
        }
        return RenderComponent(user, component, layout, componentProps, rest);
    }}
    />
)

export default connect(
    state => ({ user: state.appContext }),
    dispatch => bindActionCreators(appContextActions, dispatch)
)(PrivateRoute)