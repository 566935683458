import { generateId, getHostUrl } from './apiWrapper';

let lastClick = undefined;

const clickHooks = [];
const onClick = function (e) {
    for (let index = 0; index < clickHooks.length; index++) {
        if (clickHooks[index] !== undefined) {
            clickHooks[index].onClick.call(clickHooks[index].context, e, lastClick);
        }
    }
    lastClick = e;
};


const setupClickDispatcher = function (store) {
    document.documentElement.ontouchstart === undefined ? document.addEventListener('click', onClick, true) : document.addEventListener('touchstart', onClick, true);
};

const registerHook = function (context, afterClick) {
    const regId = generateId();
    clickHooks.push({ id: regId, onClick: afterClick, context });
    return regId;
}

const unregisterHook = function (regId) {
    const index = clickHooks.findIndex(x => x.id === regId);
    if (index > 0) {
        clickHooks.splice(index, 1);
    }
}

const isClickTo = function (clickEvent, verifyArray) {
    if (Array.isArray(verifyArray)) {
        let res = false;
        for (let index = 0; index < verifyArray.length; index++) {
            res = res || isClickToInner(clickEvent, verifyArray[index]);
        }
        return res;
    } else {
        return isClickToInner(clickEvent, verifyArray);
    }
}

const isClickToInner = function (clickEvent, item) {
    if (clickEvent === null || clickEvent === undefined || clickEvent.target === null || clickEvent.target === undefined || item === null || item === undefined) {
        return false;
    }

    const eb = clickEvent.target.getBoundingClientRect();
    const ib = item.getBoundingClientRect();

    return ib.left <= eb.left && ib.right >= eb.right && ib.top <= eb.top && ib.bottom >= eb.bottom;
}
const isClickToClass = function (clickEvent, classToCheck) {
    if (clickEvent === null || clickEvent === undefined || clickEvent.target === null || clickEvent.target === undefined || clickEvent.target.classList === undefined) {
        return false;
    }
    return clickEvent.target.classList.contains(classToCheck);

}

export { setupClickDispatcher, registerHook, unregisterHook, isClickTo, isClickToClass }