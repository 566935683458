import './function-fixes.js';
//import './css/bulma.min.css';
//import './css/main.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import { createBrowserHistory } from 'history';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { LocalizeProvider } from "react-localize-redux";
import { ToastContainer } from 'react-toastify';
import { setupInterceptors, setupRedirecCore } from './store/apiWrapper'

// app js

import registerServiceWorker from './registerServiceWorker';
import configureStore from './store/configureStore';
import App from './app';
import { setupClickDispatcher } from './store/clickWrapper'

// style
import 'react-toastify/dist/ReactToastify.css';
// style bulma
import './css/style.scss';

library.add(fas)

const history = createBrowserHistory({ basename: document.getElementsByTagName('base')[0].getAttribute('href') });
const store = configureStore(history, window.initialReduxState);

setupInterceptors(store, history);
setupClickDispatcher(store)
setupRedirecCore(store, history);

ReactDOM.render(
    <Provider store={store}>
    <LocalizeProvider store={store}>
    <ConnectedRouter history={history}>
    <React.Fragment>
    <App />
    <ToastContainer
closeOnClick
position='bottom-right'
    />
    </React.Fragment>
    </ConnectedRouter>
    </LocalizeProvider>
    </Provider>, document.getElementById('root'));

registerServiceWorker();