import React from 'react';
import { LOCATION_CHANGE } from 'react-router-redux';
import { updateObject, updateObjectProperty } from './storeFunctions';
import { apiGet, apiPost, apiPut, parseQuery, hasPermission, redirectTo } from './apiWrapper';

const initType = "INITIATE_TABLEAU_SETTINGS_REQUEST";
const failType = "RECEIVE_REQUEST_TABLEAU_SETTINGS_FAIL";
const successType = "RECEIVE_REQUEST_TABLEAU_SETTINGS_SUCCESS";

const tableauInitialState = {isRequesting: false, result: null };
const initialState =
{
    tableauSettings: {...tableauInitialState}
};

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case LOCATION_CHANGE:
            return initialState;
        case initType:
            return updateObject({ ...state }, { tableauSettings: updateObject(state.tableauSettings, { isRequesting: true, result: null }) })
        case failType:
            return updateObject({ ...state }, { tableauSettings: updateObject(state.tableauSettings, { isRequesting: false, result: null }) })
        case successType:
            return updateObject({ ...state }, { tableauSettings: updateObject(state.tableauSettings, { isRequesting: false, result: action.data }) })
        default:
            return state;
    }
}

function ensureTableauSettings(dispatch, getState) {
    if (!getState().tableau.tableauSettings || 
        (getState().tableau.tableauSettings.result === null && getState().tableau.tableauSettings.isRequesting === false)) {
        dispatch({ type: initType });
        apiGet(`api/tableau/get-settings`, null,
            response => dispatch({ type: successType, data: response.data }),
            () => dispatch({ type: failType })
        );
    }
}

export const tableauSettingsActions = {
    ensureTableauSettings: () => async (dispatch, getState) => ensureTableauSettings(dispatch, getState),
    insertTableauSettings: (result) => dispatch => dispatch({ type: successType, data: result }),
};