import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames'
import { bindActionCreators } from 'redux';
import { appContextActions } from '../store/appContextStore'
import { withLocalize } from "react-localize-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { parseQuery, isObjectPathDefined, apiPost } from '../store/apiWrapper';

import InputControl from './common//formFields/inputControl';

class LostPwdComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '', email: '', token: '', loading: false, error: false, stage: 1, completed: false
        };

        const q = parseQuery(window.location.search);
        if (isObjectPathDefined(q, 'u') && isObjectPathDefined(q, 'rpt')) {
            this.state.loading = true;
            this.resetPwdConfirm(q.u, q.rpt);
            this.state.stage = 2;
        }
    }

    changeEvent = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    resetPwdConfirm = (token, user) => {
        apiPost('api/account/confirm-reset-pwd', undefined, { token, user },
            (res) => this.setState({ error: !res.data.success, message: res.data.errorMessage }),
            (err) => this.setState({ error: true, message: err }),
            () => this.setState({ loading: false, completed: true }));
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({ loading: true, error: false, message: undefined });
        apiPost('api/account/reset-pwd', undefined, { userName: this.state.username, email: this.state.email },
            (res) => this.setState({ error: !res.data.success, message: res.data.errorMessage }),
            (err) => this.setState({ error: true, message: err }),
            () => this.setState({ loading: false, completed: true }));
    }

    renderStageOne = () => {
        if (this.state.completed) {
            return (
                <div className="columns is-centered">
                <div className="column is-half">
                    <div className={classnames({ 'notification': true, 'is-primary': !this.state.error, 'is-danger': this.state.error })} dangerouslySetInnerHTML={{ __html: this.state.message }}></div>
                </div>
                </div>
            );
        }

        return (
            <form onSubmit={(e) => this.handleSubmit(e)}>
                <div className="columns">
                    <div className="column is-4 is-offset-4">
                        <h1 className="subtitle is-4">{this.props.translate('PwdReset.FormCaption')}</h1>
                    </div>
                </div>

                <div className="columns">
                    <div className="column is-4 is-offset-4">
                        <div className="field">
                            <p className="control has-icons-left has-icons-right">
                                <input className="input" type="text" placeholder={this.props.translate('PwdReset.Username')} 
                                    disabled={this.props.loading} 
                                    name='username' 
                                    value={this.state.username} 
                                    onChange={this.changeEvent} />
                                <span className="icon is-small is-left"><FontAwesomeIcon icon="user" /></span>
                            </p>
                        </div>
                        <div className="field">
                            <p className="control has-icons-left">
                                <input className="input" type="email" placeholder={this.props.translate('PwdReset.UserEmail')} 
                                    disabled={this.props.loading} 
                                    name='email' 
                                    value={this.state.email} 
                                    onChange={this.changeEvent} />
                                <span className="icon is-small is-left"><FontAwesomeIcon icon="user" /></span>
                            </p>
                        </div>
                    
                        <div className="field columns">
                            <div className="column">
                                <p className="control">
                                    {!this.state.loading && (<input type="submit" value={this.props.translate('PwdReset.BtnReset')} className="is-success button is-pulled-right" disabled={this.state.loading} />)}
                                    {this.state.loading && (<span className="is-info button is-pulled-right"><FontAwesomeIcon icon="cog" spin /></span>)}
                                </p>
                            </div>
                        </div>
                    </div>
                </div> 
                   
            </form>
        );
    };

    renderStageTwo = () => {
        return (
            <div className="columns is-centered">
                <div className="column is-half">
                    {this.state.completed && <div className={classnames({ 'notification': true, 'is-primary': !this.state.error, 'is-danger': this.state.error })} dangerouslySetInnerHTML={{ __html: this.state.message }}></div>}
                    {!this.state.completed && this.state.loading && (<span>processing <FontAwesomeIcon icon="cog" spin /></span>)}
                </div>
            </div>
        );
    }

    render() {
        return (
            <section className="section">
                
                    {this.state.stage === 2 && this.renderStageTwo()}
                    {this.state.stage !== 2 && this.renderStageOne()}
            </section>
        );
    }
}

export default withLocalize(connect(
    state => state.appContext,
    dispatch => bindActionCreators(appContextActions, dispatch)
)(LostPwdComponent));
