import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { withLocalize } from "react-localize-redux";

class FieldHorizontal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {
        return (
            <div className="field is-horizontal">
            <div className="field-label is-normal" style={{ whiteSpace: "nowrap" }}><label className="label">{this.props.label}</label></div>
            <div className="field-body">
                <div className="field">
                    {this.props.children}
                </div>
                {this.props.isSecondField &&
                    <div className="field">
                        {this.props.secondField}
                    </div>
                }
            </div>
        </div>
        );
    }
}

FieldHorizontal.defaultProps = {
    isSecondField: false,
    label: "",
    secondField: {}
}

FieldHorizontal.propTypes = {
    isSecondField: PropTypes.bool,
    label: PropTypes.string,
    secondField: PropTypes.object
}
 
export default FieldHorizontal;