import React, { Component } from 'react';
import NavMenu from './navMenu';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { appContextActions } from '../../store/appContextStore'
import { withRouter } from "react-router";

class Layout extends Component {
    render() {
        return (
            <React.Fragment>
                <NavMenu />
                <div className="container is-fluid body-content">
                    {this.props.children}
                </div>
            </React.Fragment>
        );
    }
}

Layout.propTypes = {
}

export default connect(
    state => state.appContext,
    dispatch => bindActionCreators(appContextActions, dispatch)
)(withRouter(Layout));